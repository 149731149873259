<template>
    <div>
        <div class="helper-box">
            <p class="align-center text-highlight-textLarge">Modify menu page order by dragging to rearrange. Click the eye to show or hide a page on the menu</p>
        </div>
        <div class="flex flex-col flex-1 h-full">
            <draggable
                :list="pages"
                item-key="website_pages_name"
                group="people"
                @start="drag=true"
                @change="updatePageSort"
                handle=".sortable-handle"
            >
                <template v-slot:item="{element: page}">
                <div class="border border-gray-200 px-4 py-3 my-2 flex items-center justify-between">
                    <div class="flex justify-between items-center w-full">
                        <div class="flex items-center">
                            <icon name="arrowsExpand"  class="sortable-handle cursor-pointer w-4 h-4 mr-3 text-gray-500" />
                            <span>{{ page.website_pages_name }}</span>
                        </div>
                        <div class="cursor-pointer" @click="updatePageVisibility(page)">
                            <icon name="eye" v-if="page.show_in_nav" class="w-5 h-5" />
                            <icon name="eyeOff" v-if="!page.show_in_nav" class="w-5 h-5 text-gray-500" />
                        </div>
                    </div>
                </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script>
import Auth from "@/mixins/Auth";
import draggable from 'vuedraggable';
import Icon from "@/components/ui/Icon";
import {mapGetters, mapActions} from "vuex";
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalNavigation from "@/mixins/ModalNavigation";

export default {
    mixins: [ Auth, NotifyMixin, ModalNavigation ],
    components: {Icon, draggable},
    computed: {
        ...mapGetters({
            getPagesByWebsite: 'pages/getPagesByWebsite',
        }),
    },
    data: () => {
        return {
            pages: [],
        }
    },
    mounted() {
        this.pages = this.getPagesByWebsite(this.$route.params.id);
    },
    methods: {
        ...mapActions({
            updatePage: 'pages/updatePage',
        }),
        updatePageSort(evt) {
            let page = evt.moved.element;
            page.page_order = evt.moved.newIndex+1;
            page.customers_id = this.customerId;
            page.originalPageName = page.website_pages_name;

            this.savePage(page);
        },
        updatePageVisibility(pageObj) {
            let page = pageObj;
            page.customers_id = this.customerId;
            page.originalPageName = page.website_pages_name;
            page.show_in_nav = !page.show_in_nav;

            this.savePage(page);
        },
        savePage(page) {
            let params = {
                websiteId: page.websites_id,
                id: page.originalPageName,
                data: page
            };
            this.$cmsDataProvider.update('updatePage', params)
            .then( () => {
                this.notifySuccess('There navigation settings were updated successfully.');
            }).catch(() => {
                this.notifyError('There was an error saving the information');
            });
        }
    },
}
</script>
